import { FC } from 'react';
import { useRouter } from '@tanstack/react-router';
import { Button, Flex, Text, View } from '@instructure/ui';
import { IconArrowStartSolid } from '@instructure/ui-icons';
import { useLibTrans } from '../i18n';

const statusCodeMessageMap = {
    401: 'error_components.unauthorized',
    404: 'error_components.could_not_find_page',
    500: 'error_components.internal_server_error',
};

interface StatusErrorPageProps {
    code: keyof typeof statusCodeMessageMap;
}

export const StatusErrorPage: FC<StatusErrorPageProps> = ({ code }) => {
    const { t } = useLibTrans('moduleErrorComponents', undefined, { useSuspense: false });
    const { history } = useRouter();

    const handleClickBack = () => {
        history.go(-1);
    };

    return (
        <View as="div" width="100%" data-testid="load-failed">
            <Flex as="div" margin="medium auto">
                <Flex.Item as="span" margin="medium auto">
                    <Text size="xx-large" weight="bold">
                        {code}
                    </Text>
                </Flex.Item>
            </Flex>
            <Flex as="div" direction="column" margin="medium auto">
                <Flex.Item as="span" margin="auto">
                    <Text size="large" weight="bold">
                        {t(statusCodeMessageMap[code])}
                    </Text>
                </Flex.Item>
                <Flex.Item as="span" margin="medium auto">
                    <Button color="primary" onClick={handleClickBack} renderIcon={<IconArrowStartSolid />}>
                        {t('error_components.go_back')}
                    </Button>
                </Flex.Item>
            </Flex>
        </View>
    );
};
