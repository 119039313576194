import { FC, memo, useEffect, useState } from 'react';

import { Mask, Overlay, Spinner } from '@instructure/ui';

import { LoadFailed } from './LoadFailed';

export type LoadingProcessStatus = 'loading' | 'failure' | 'success' | 'non-blocking-failure';

type Process = {
    name: string;
    status: LoadingProcessStatus;
};

export type FailedProcess = Process & {
    status: 'failure';
    message: string;
};

type AppLoaderProps = {
    children: React.ReactNode;
    processes: (() => LoadingProcess)[];
    minimumLoadingTime: number;
};

export type LoadingProcess = Process | FailedProcess;

export const AppLoader: FC<AppLoaderProps> = memo(({ children, processes, minimumLoadingTime }) => {
    const loadingProcesses: Process[] = processes.map((process) => process());

    const [minimumDurationPassed, setMinimumDurationPassed] = useState<boolean>((minimumLoadingTime || 0) <= 0);

    useEffect(() => {
        if (minimumLoadingTime) {
            setTimeout(() => setMinimumDurationPassed(true), minimumLoadingTime);
        }
    }, [minimumLoadingTime]);

    if (!minimumDurationPassed || loadingProcesses.some((process) => process.status === 'loading')) {
        return (
            <Overlay open={true} transition="fade" label="Loading" shouldReturnFocus shouldContainFocus>
                <Mask>
                    <Spinner renderTitle="Loading" size="large" margin="0 0 0 medium" />
                </Mask>
            </Overlay>
        );
    }

    function isFailureProcess(process: Process): process is FailedProcess {
        return process.status === 'failure';
    }

    if (loadingProcesses.some((process) => process.status === 'failure')) {
        const messages = loadingProcesses.filter(isFailureProcess).map((process) => process.message);

        return (
            <div style={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
                <LoadFailed messages={messages} />
            </div>
        );
    }

    return <div>{children}</div>;
});
